@import url('https://fonts.googleapis.com/css2?family=Tinos&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

:root {
  --gold: #FFD700;
  --red: #E25454;
  --blue: #A7C7E7,;
  --LightGold: #fbeeee;
  --LightGrey: #F5F5F5;
  --TextGrey: #343434;
  --DarkestGrey: #343434;
  --DarkerGrey: #444444;
  --OffWhite: #f8f8f8;
  --TextOffWhite: #f8f8ff;
  --Gunmetal: #495256;
  --LightGunmetal: #818589;
  --HRGrey: rgba(33, 35, 38, .2);

  --ArtDecoGrey:#9aa59f;
  --ArtDecoCyan:#193741;
  --ArtDecoBlack:#060606;
  --ArtDecoBrown:#b35a20;
  --ArtDecoGold:#e29c2d;

  --ModernLightBlue:#68b0c3;
  --ModernLighterBlue:#92c4cb;
  --ModernOffWhite:#fbf3e7;

  --VintageOffWhite:#fff8f2;
  --VintageDarkBrown: #4a3730;

  --ArtNouveauBorwn:#772311;
  --ArtNouveauRed:#d96938;
  --ArtNouveauPink:#f4b39c;
  --ArtNouveauYellow:#f8e7ab;
  --ArtNouveauLightYellow:#fdf6da;
  --ArtNouveauDarkBrown: #2c0e0c;
  --ArtNouveauDarkGreen: #203d21;
  --ArtNouveauRed: #ca0c0b;
  --ArtNouveauPink: #e9aeae;
  --ArtNouveauOrange: #e1874b;
  --ArtNouveauOffWhite: #fbeee4;
  --ArtNouveauPurple: #78599e;

  --DarkPrimary: #723935;
  --DarkSecondary: #2C0E0C;
  --HighlightPrimary: #E29C2D;
  --OffWhiteBackground: #FFFBF8;
  --DarkOffWhiteBackground: #fbeee4;
  --OffWhiteText: #FBF3E7;
  --DarkText: #4A3730;
}

body {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}

li{
  margin-bottom:20px;
}

.NoDisplay{
  display:none;
}

.FlexDisplay{
  display:flex;
}

.Uppercase{
  text-transform: uppercase;
}

.DarkPrimary{
  background-color: var(--DarkPrimary);
}

.DarkGradient{
  background-image: linear-gradient(var(--DarkPrimary), var(--DarkSecondary));
}

.DarkSecondary{
  background-color: var(--DarkSecondary);
}

.HighlightPrimary{
  color:var(--HighlightPrimary)
}

.OffWhiteBackground{
  background-color: var(--OffWhiteBackground);
}

.DarkOffWhiteBackground{
  background-color: var(--DarkOffWhiteBackground);
}

.OffWhiteText{
  color:var(--OffWhiteText);
}

.DarkText{
  color:var(--DarkText);
}

.Serif{
  font-family: DM Serif Display;
}

.SimpleSerif{
  font-family:Cormorant;
}

.SansSerif{
  font-family:jost;
}

.PositionRelative{
  position:relative
}

.PositionAbsolute{
  position:absolute
}

.Inflater{
  height:3px;
  width:60px;
  background-color: var(--HighlightPrimary);
  Bottom:0;
  margin-bottom:-10px;
}

.Inflated::after{
  width:10px;
  height:10px;
  background-color: var(--HighlightPrimary);
  margin:auto;
  position:relative;
  content: " ";
  display:block;
  margin-top: -3.5px;
  transform: rotate(45deg);
}

.Cookies{
  position:fixed;
  Bottom:0;
  width:100vw;
  height:125px;
  z-index:999999;
  color:var(--OffWhiteText);
  opacity:.95;
  font-size:1.2em;
}

.CookiesRow{
  margin:auto;
  padding-left: 20px;
  padding-right:20px;
  max-width:600px;
}

.CookiesButton{
  color:var(--OffWhiteText);
  cursor:pointer;
}

.CookiesButton:hover{
  color:var(--HighlightPrimary);
  text-decoration: underline;
}

.Inflated{
  width:125px;
}

.InflaterText{
  z-index:10;
}

.FormalButton{
  width:175px;
  height:30px;
  margin:10px;
  font-size:1.5em;
  margin-bottom:35px;
}

.UpperLeft, .UpperRight, .BottomLeft, .BottomRight{
  padding:5px;
}

.UpperRight{
  position:fixed;
  z-index:9999;
  Top:0;
  Right:0;
  transform: rotate(270deg);
}

.UpperLeft{
  position:fixed;
  z-index:9999;
  Top:0;
  Left:0;
  transform: rotate(180deg);
}

.BottomRight{
  position:fixed;
  z-index:9999;
  Bottom:0;
  Right:0;
}

.BottomLeft{
  position:fixed;
  z-index:9999;
  Bottom:0;
  Left:0;
  transform: rotate(90deg);
}

.BlueBackground{
  background-image: linear-gradient(var(--ModernLightBlue), var(--ModernLighterBlue));
}

.LandingDiamond{
  height:10px;
  width:10px;
  border: var(--HighlightPrimary) solid 3px;
  background-color: var(--DarkPrimary);
  transform: rotate(45deg);
  display:none;
}

.LandingContainer{
  width:100vw;
  height:100vh;
  color:var(--ArtDecoGold);
  overflow:hidden;
  margin-top:-50px;
  justify-content: center;
  align-items: center;
}

.LandingContent{
  justify-content: center;
  margin:auto;
}

.LandingPageButtons{
  display:flex;
  flex-direction: column;
  margin-top:20px;
}

.FlexEnd{
  justify-content: flex-end;
}

.UltraFastTransition{
  transition:100ms;
}

.FastTransition{
  transition:200ms;
}

.MediumTransition{
  transition:500ms;
}

.SlowTransition{
  transition:1s;
}

.VerySlowTransition{
  transition:2s;
}

.LandingTitle{
  font-size:4em;
  line-height: 1.25em;
  letter-spacing: .25em;
  text-transform: uppercase;
}
.LandingSubTitle{
  font-size:1.5em;
  margin-bottom:40px;
}

.Chevron{
  margin-top:5px;
  margin-bottom:20px;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cdcdcd; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #a6a6a6; 
}

body{
  font-family: Tinos;
  font-size:12pt;
  color:var(--VintageDarkBrown);
  background-color:var(--OffWhiteBackground);
}

a {
  color:#343434;
  font-style: normal;
  text-decoration: none;
}

.NormalLink {
  color: var(--ArtNouveauPink);
  text-decoration: underline;
}


.TopBar{
  width: 100%;
  height:50px;
  background-color: var(--Gunmetal);
  font-family: DM Serif Display;
  position:sticky;
  Top:0;
  Left:0;
  z-index:100;
  color: var(--TextOffWhite)
}

.ModernTopBar{
  width: 100%;
  height:50px;
  font-family: DM Serif Display;
  position:sticky;
  Top:0;
  Left:0;
  color: var(--ArtDecoGold);
  z-index:100;
}

.ModernOffWhiteText{
  color: var(--ModernOffWhite) !important;
}

.GoldText{
  color: var(--ArtDecoGold) !important;
}

.ModernTopBarContent{
  display:flex;
  flex-direction: row;
  height:100%;
  margin:auto;
  z-index:999;
  position:relative;
}

.Gradient{
  position: fixed;
  top: 0;
  width: 100%;
  height: 85px;
  z-index: 100;
  pointer-events: none;
  opacity:.97;
}

.OffWhiteGradientBackground{
  background: linear-gradient(var(--OffWhiteBackground), 85%, rgba(255, 255, 255, 0));
}

.DarkGradientBackground{
  background: linear-gradient(var(--DarkPrimary), 85%, rgba(255, 255, 255, 0));
}

.TopBarContent{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  height:100%;
  margin:auto;
}

.FlexCenter{
  display:flex;
  justify-content: center;
  align-content: center;
}

.Caudex{
  font-family:Tinos;
}

.Logo{
  font-size:2em;
  height:100%;
  margin-left:20px;
  letter-spacing:.2em;
  align-items: center;
}

.Logo:Hover{
  cursor:pointer;
}

.TextContainer{
  max-width: 725px;
}

.FirstLetter{
  color:var(--ArtDecoGold);
  font-size:5em;
  float: left;
  padding-right:10px;
  line-height:100px;
  height:80px;
}

.MainColumnWidth{
  width:100%;
  max-width:100%;
  min-width: 90%;
}

.BasicPage{
  margin:auto;
  max-width:750px;
}

.Hamburger{
  width:65px;
}

.ModernHamburger{
  width:50px;
  fill:var(--ArtDecoGold);
}

.SearchButtonTopBar{
  width:50px;
  fill:var(--ArtDecoGold);
}

.HamSVG{
  fill:var(--ArtDecoGold);
}

.HamSVG{
  fill:var(--ArtDecoGold);
}

.TopBarButtons{
  justify-content: center;
  align-content: center;
  font-size:1em;
  margin-right:20px;
  display:none;
  flex-direction: row;
  color: var(--TextOffWhite)
}

.TextButton{
  margin:auto;
  padding-left:10px;
  padding-right:10px;
  color: var(--TextOffWhite)
}

.TextButton:hover{
  cursor:pointer;
}

.LoadingContainer{
  height:100px;
  width:100px;
}

.LoadingIcon{
  width:15px;
  height:15px;
  border: var(--HighlightPrimary) solid 3.5px;
  margin:auto;
  position:relative;
  content: " ";
  display:block;
  margin-bottom: -8px;
  transform: rotate(45deg);
  margin-left:20px;
  margin-right:20px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.LoadingIcon::after{
  width:5px;
  height:5px;
  background-color: var(--HighlightPrimary);
  content: " ";
  display:block;
}

.OverflowHidden{
  overflow:hidden;
}

.TopNudge{
  height:20px;
  width:100%;
}

.NoNudge{
  height:0px;
  width:100%;
}

.DonateButton{
  max-width:200px;
  max-height:40px;
  margin:auto;
  margin-top:9px;
  margin-bottom:9px;
}

.SubscribeButton, .DialogButton{
  background-color: var(--ArtDecoGold);
  height:37px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 5px;
  color:white;
  align-content: center;
  justify-content: center;
  display:flex;
  margin:auto;
}

.DialogButton{
  font-size:1.25em;
  height:35px;
  width: 100px;
  margin-bottom:40px;
}

.DialogText{
  font-size: 1.1em;
  width:75%;
  margin:auto;
  margin-top:15px;
  margin-bottom:10px;
}

.DialogHeading{
  font-size:2em;
  text-align:center;
  margin-bottom:20px;
}

.Dialog{
  margin:auto;
  height:600px;
  width:600px;
  max-width:90vw;
  position:relative;
  border-radius: 5px;
}

.DialogContainer{
  position:fixed;
  Top:0;
  Left:0;
  height:calc(100vh + 15px);
  width:calc(100vw + 8px);
  z-index:300;
  background: rgba(0, 0, 0,.3);
}

.DialogChildContainer{
  height:95%;
}

.DialogTopBar{
  height:5%;
  display:flex;
  justify-content: flex-end;
}

.FlexColumn{
  display:flex;
  flex-direction:column;
}

.FlexRow{
  display:flex;
  flex-direction:row;
}

.ContentGrid{
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  width:100%;
  justify-content: flex-start;
  margin-bottom:10px;
}

.ContentCardContainer{
  width:85%;
  margin:auto;
}

.ContentCardContainer:hover{
  cursor:pointer;
}

.ContentCard{
  margin-top:25px;
  margin-bottom: 25px;
  width:100%
}

.EmptyCard{
  display:none;
}

.RichGold{
  color:var(--ArtDecoGold)
}

.ContentCardTitle{
  margin:auto;
  flex: 1 1 0;
  justify-content: center;
  align-content: center;
  text-align:left;
}

.CardTitle{
  font-size:2em;
}

.CardByline{
  font-size:1em;
  justify-content:space-between
}

.CardMetadata{
  text-align: left;
  padding-top:5px;
  padding-bottom:15px;
  align-items: center;
  justify-content: space-between;
  width:85%;
  margin:auto;
}

.BottomShadow{
  box-shadow: rgba(33, 35, 38, .2) 0px 10px 10px -10px,
  rgba(33, 35, 38, .1) 0px 1px 0px 0px;
}

.FeatureCard{
  margin:auto;
  padding-top:25px;
  margin-top:50px;
  text-align:center;
  flex-basis: content;
  width:100vw;
  padding-bottom:25px;
  background-color: black;
  width:100%;
  height:100%;
}

.FeatureCardFade{
  position:absolute;
  Bottom:0;
  height:65%;
  width:100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  opacity:.92;
  z-index:20;
}

.FeatureCardDeco{
  background-color: black;
  background-image: url('https://cornicemag.nn.r.appspot.com/images/bear.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin:auto;
  text-align:center;
  flex-basis: content;
  width:100%;
  height:100%;
  position:relative;
  touch-action: none;
}

.FeatureCardDecoContainer{
  margin:auto;
  flex-basis: content;
  width:100vw;
  height:45vh;
  position:relative;
  margin-bottom:25px;
}

.FeatureCardArrow{
  position:absolute;
  top:50%;
  z-index:50;
  font-size:20pt;
}

.LeftAbsolute{
  left:20px;
}

.RightAbsolute{
  right:20px;
}

.Grabber:active{
  cursor: grabbing;
}

.FeatureCardMetaContainer{
  justify-content: flex-end;
  z-index:30;
  width:100vw;
  margin:auto;
  margin-bottom:20px;
}

.FeatureCardContainer{
  margin:auto;
  text-align:left;
  height:66vh;
}

.FeatureCardImgContainer{
  margin-bottom: 20px;
  width:100%;
}

.FeatureCardTitle{
  font-size:3em;
  margin-left:25px;
}

.FeatureCardBlurb{
  font-size:1.1em;
  margin-bottom:10px;
  margin-left:25px;
  margin-right:25px;
}

.FeatureCardByline{
  font-size:1.1em;
  margin-bottom:10px;
  margin-left:25px;
  margin-right:25px;
}

.FeatureCardButtons{
  margin:auto;
  margin-bottom: 30px;
  margin-top:10px;
}

.FeatureButton{
  width:10px;
  height:10px;
  border: var(--HighlightPrimary) solid 3px;
  margin:auto;
  position:relative;
  content: " ";
  display:block;
  margin-bottom: -8px;
  transform: rotate(45deg);
  margin-left:20px;
  margin-right:20px;
}

.FeatureButtonActive{
  display:flex;
  justify-content: center;
  align-items: center;
}

.FeatureButtonActive::after{
  width:5px;
  height:5px;
  background-color: var(--HighlightPrimary);
  content: " ";
  display:block;
}

.TextLeft{
  text-align: left;
}

.TextCenter{
  text-align:center;
}

.SpaceBetween{
  justify-content: space-between;
}

.CardDivider{
  height:100%;
  width:20px;
  text-align:center;
  margin:auto;
  margin-top:11%;
  opacity: .8;
  display:none;
}

.CardImgContainer{
  flex: 1 1 0;
  margin-bottom:20px;
}

.CardImg{
  min-height:137px;
  width: 100%;
}

.LeftBorder{
  border-left:var(--HighlightPrimary) solid 5px;
}

.RedShadow{
  box-shadow: rgba(238, 105, 105, 0.2) 0px 2px 4px 0px, rgba(238, 105, 105, 0.4) 0px 2px 16px 0px;
}

.RedShadowHover:hover{
  box-shadow: rgba(238, 105, 105, 0.2) 0px 2px 4px 0px, rgba(238, 105, 105, 0.4) 0px 2px 16px 0px;
}

.RedShadowTextFirm{
  text-shadow: rgba(238, 105, 105, 0.2) 0px 2px 4px, rgba(238, 105, 105, 0.4) 0px 2px 16px;
}

.RedShadowText:Hover{
  text-shadow: rgba(238, 105, 105, 0.2) 0px 2px 4px, rgba(238, 105, 105, 0.4) 0px 2px 16px;
}

.RedText{
  color: var(--ArtDecoGold)
}

.EvenShadow{
  box-shadow: rgb(14 30 37 / 12%) 0px 1px 3px 0px, rgb(14 30 37 / 32%) 0px 2px 8px 0px;
}

.ContentContainer{
  width:90%;
  margin:auto;
  height:100%;
  margin-top:50px;
  margin-bottom:50px;
  padding-bottom:25px;
  min-height:100vh;
}

.ContentMetadata{
  text-align:center;
  height:310px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom:-20px;
  z-index:50;
}

.DecoPattern{
  position: absolute;
  opacity: .15;
  height: 300px;
  width: 100vw;
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
  background-size: auto;
  margin-top: -18px;
}

.ContentTitle{
  font-size:2.5em;
  margin-bottom:5px;
  z-index:20;
}

.ContentByLine{
  font-size:1.5em;
}

.ContentPara, .PoemPara{
  margin:auto;
  margin-top:1em;
  font-size:1.1em;
}

.PoemPara{
  margin-top:.1em;
}

.ContentHeading, h1, h2{
  margin:auto;
  margin-top:1em;
  font-size:1.1em;
  border-bottom:rgba(226, 156, 45) solid 2pt;
}

.TextContainer h2{
  font-size:1.5em;
  margin-bottom:1em;
  padding-bottom:1px;
  color:var(--HighlightPrimary);
  text-transform: uppercase;
  text-align: center;
  font-weight:normal;
  margin-top:50px;
}

.TextContainer h2::after{
  background-color: var(--OffWhiteBackground);
  margin-top: 10px;
}

h3{
  font-size: 1.25em;
  margin-top:1em;
}

.ContentHeading::after, h1::after, h2::after{
  width:10px;
  height:10px;
  background-color: var(--DarkPrimary);
  border: var(--HighlightPrimary) solid 2px;
  margin:auto;
  position:relative;
  content: " ";
  display:block;
  margin-bottom: -8px;
  transform: rotate(45deg);

}



.h1{
  margin-top:1.25em;
  font-size:2em;
}

.h2{
  margin-top:1em;
  font-size:1.5em;
}

.ProgressBar{
  display:block;
}

.Percent{
  position:fixed;
  display:block;
  margin-top: 20px;
}

.ProgressInactive{
  background-color: transparent;
  width:100vw;
  height:7px;
  position:fixed;
  Bottom:0;
  max-width:100%;
}

.ProgressActive{
  background-color: var(--ArtDecoGold);
  z-index: 100;
  height:100%;
  position:sticky;
  max-width:100%;
}

.Pointer{
  cursor: pointer;
}

.Footer{
  color:var(--ModernOffWhite);
  margin-top:50px;
  padding-top:20px;
  padding-bottom:20px;
}

.FooterContent{
  margin:auto;
  width:90%;
}

.FooterColumns{
  width:100%;
  justify-content: space-between;
  display:flex;
  flex-direction: column;
  margin-bottom:25px;
}

.FooterColumn{
  width:95%;
  margin-top:20px;

}

.FooterTitle{
  font-size:2.25em;
  margin-bottom:10px;
  margin-top:20px;
  color:var(--ArtDecoGold)
}

.RedButton{
  background-color: #ee6969;
  color:white;
  text-align:center;
  width:90px;
  height:40px;
  font-size:1.25em;
  margin:10px;
  display:flex;
  border-radius: 5px;
  font-family:DM Serif Display;
}

.RedButton:hover{
  cursor:pointer;
}

.AutoMargin{
  margin:auto;
}

.CardBlurb{
  margin-top:10px;
  font-size:1.25em;
  margin-bottom:15px;
}

.EndDeco{
  margin-top:50px;
  transform: rotate(180deg);
}

.SocialMedia{
  margin-top:10px;
}

.SocialIcon{
  width:20px;
  margin:5px;
}

.SocialIcon:hover{
  filter: invert(79%) sepia(82%) saturate(3973%) hue-rotate(337deg) brightness(93%) contrast(90%);
}

.HighlightHover:hover{
  color:var(--HighlightPrimary)
}

.Author, .AuthorNoImage{
  margin:auto;
  margin-top:70px;
  justify-content: space-between;
  border-radius: 5px;
  display:flex;
  flex-direction: column-reverse;
  width:85%;
  max-width:725px;
}

.AuthorNoImage{
  justify-content: center;
}

.AuthorName{
  font-size:1.6em;
  margin-bottom:10px;
}

.AuthorSummary{
  padding-bottom:20px;
  font-size:1.1em;
}

.AuthorHeadshot{
  margin:auto;
  max-width:150px;
  border-radius:100px;
  margin-top:35px;
}

.EmptyAuthorHeadshot{
  max-width:35px;
  margin:auto;
}

.sidebar{
  position:fixed;
  height:100%;
  width:75%;
  Top:0;
  Right:0;
  margin-top: 50px;
  background-color: var(--LightGunmetal);
  opacity:.98;
  z-index:100;
  color:var(--TextOffWhite);
}



.LandingSidebar{
  position:fixed;
  height:100%;
  width:300px;
  Top:0;
  Right:0;
  opacity:.98;
  z-index:9999;
  color:var(--ArtDecoGold);
  font-family: DM Serif Display;
  display:flex;
  align-content: center;
}

.SidebarClose{
  position:absolute;
  Top:0;
  Right:0;
}

.HiddenSidebar{
  margin-right:-300px;
}

.SideBarButton {
  font-size:1.4em;
  text-align:left;
  margin-left:1em;
  margin-top:1em;
  color:inherit;
}

.SideBarSubscribe {
  font-size:1.4em;
  text-align:left;
  margin-left:1em;
  margin-top:1em;
  width:100px;
  background-color: var(--ArtDecoGold);
  color:var(--TextOffWhite);
  border-radius:5px;
  display:flex;
  justify-content: center;
  padding:5px;
}

.NoMobile, .NoMobileFlex{
  display:none;
}

.SearchBar{
  width:100% !important;
  margin-top:20px;
  align-items: center;
  margin-bottom:20px;
  border: rgb(200, 200, 200) solid 4px;
  max-width:900px;
}

.SearchForm{
  width:100%;
}

.SearchInput{
  width:100%;
  font-size:1.1em;
  border-color:transparent;
  padding-left:15px;
}

.SearchInput:focus{
  outline:none;
  background-color:var(--LightGrey);
}

.FooterLogo{
  margin:0;
}

.FillBlack{
  height:100%;
  width:100%;
  background-color: black;
}

.SearchButton{
  width:100px;
  height:40px;
  font-size:1.1em;
  border-left: rgb(200, 200, 200) solid 4px;
}

.SearchButton:active:hover{
  background-color: var(--DarkPrimary);
  color:var(--HighlightPrimary);
}

.SearchMessage{
  margin-bottom:40px;
}

.AlignCenter{
  align-items: center;
}

.SearchResult{
  position:relative;
  margin:auto;
  margin-top:20px;
  padding-bottom:20px;
  max-width:900px;
  border-bottom: var(--HighlightPrimary) solid 1px;
}

.SearchMetadata{
  margin-left: 20px;
  height:80%;
  color:var(--DarkText)
}

.SearchBlurb{
  margin-top:10px;
}

.SearchTitle{
  margin-top:5px;
  font-size:1.52em;
}

.SearchImageContainer{
  min-width:120px;
  max-width:120px;
  margin:auto;
  background-color:(var(--VintageDarkBrown))
}

.SearchImage{
  min-width:120px;
  max-width:120px;
  margin:auto;
}

.SearchDate{
  margin-top:15px;
}

.SearchByLine{
  margin-top:25px;
}

.CenterText{
  text-align:center;
}

.FullOpacity{
  opacity:1;
}

.PartialOpacity{
  opacity:.85;
}

.NoOpacity{
  opacity:0;
}

.Underline, .UnderlineHover:Hover{
  text-decoration: underline;
}

.FullWidth{
  width:100%
}

.FullHeight{
  height:100%
}

.FullWidthViewport{
  width:100vw
}

.FullHeightViewport{
  height:100vh
}

.FadeInDiv, .SearchFadeInDiv{
  position:fixed;
  Top:0;
  Left:0;
  z-index:80;
  pointer-events: none;
}

.SearchFadeInDiv{
  position:absolute;
  Top:0;
  Left:0;
  z-index:80;
  pointer-events: none;
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.NoPointerEvents{
  pointer-events: none;
}

.TransitionEaseOut{
  
}

.JustifyCenter{
  justify-content: center;
}

.JustifyApart{
  justify-content: space-between;
}

.ArrowContainer{
  position:absolute;
  Top:0;
  Left:0;
}

.Arrow{
  margin:auto;
  margin-left:10px;
  font-size:3em;
  margin-right:10px;
  height:50px;
  color:var(--HighlightPrimary);
  z-index:999;
}

.Hidden{
  display:none;
}

@media screen and (min-width: 650px) {
  .SearchImageContainer{
    min-width:175px;
  }
  .SearchImage{
    max-width:175px;
  }
  .sidebar{
    width:50%;
  }
  .MainColumnWidth{
    width:85%;
    max-width:85%;
    min-width: 85%;
  }
  .FooterColumns{
    flex-direction: row;
  }
  .SubscribeButton{
    margin-right: 20px;
  }
  .Author{
    flex-direction:row;
  }
  .AuthorSummary{
    padding-bottom:0px;
    padding-right:30px;
  }
  .AuthorHeadshot{
    margin-right: 50px;
    margin-top:auto;
    margin-bottom:auto;
  }
  .LandingSidebar{
    width:400px;
  }
  .HiddenSidebar{
    margin-right:-400px;
  }
}

@media screen and (min-width: 875px) {
  .MobileOnly{
    display:none;
  }
  .NoMobile{
    display:block;
  }
  .NoMobileFlex{
    display:flex;
  }

  .ContentTitle{
    font-size:3em;
  }

  .LandingContent{
    flex-direction: row;
    max-height:1000px;
    position:relative;
    max-width:85%;
    width:800px;
    
  }
  .Chevron{
    margin:auto;
    margin-left:-100px;
  }
  .LandingCornice{
    position:absolute;
    left:0;
  }
  .LandingPageButtons{
    flex-direction: column;
    position:absolute;
    right:0;
    margin-right:70px;
    margin-top:0px;
  }
  .FormalButton{
    margin-bottom:30px;
    margin-top:30px;
  }


  .FeatureCardMetaContainer{
    width:85%;
    max-width:85%;
  }
  .FeatureCardTitle, .FeatureCardByline, .FeatureCardBlurb{
    margin-left:0;
    margin-right:0;
  }
  .FeatureCardTitle{
    font-size:3.5em;
  }

  .ContentPara, .PoemPara{
    font-size:1.3em;
  }
  .TextContainer h2{
    font-size:1.75em;
  }
  
  .AuthorSummary{
    font-size:1.3em;
  }

  .CardDivider{
    display:block;
  }
  .TopBarButtons{
    margin-right:0;
    display:flex;
  }
  .Hamburger{
    display:none;
  }
  .sidebar{
    display:none;
  }
  .CardTitle{
    margin-top:5px;
    font-size:2em;
  }
  .CardByline{
    font-size:1.25em;
  }
  .CardBlurb{
    text-align:left;
  }
  .FooterColumn{
    width:33%;
  }
  .ContentGrid{
    flex-direction: row;
    justify-content: space-between;
    min-height:325px;
    margin-bottom:20px;
    margin-top:10px;
  }
  .FeatureCard{
    margin-top: 25px;
    margin-bottom:20px;
    padding-bottom:10px;
    min-width:100%;
    padding-top:0;
    padding-bottom:0;
  }
  .FeatureCardContainer{
    flex-direction:row;
    padding-bottom:10px;
    width:100%;
    text-align:center;
    height:50vh;
  }
  .FeatureCardImgContainer{
    flex: 1.25 1.25 0
  }
  .CardImgContainer{
    margin-bottom:0;
  }
  .CardBlurb{
    font-size:1.4em;
  }
  .ContentCard{
    box-shadow: transparent 0 0 0;
    max-width:30%;
    min-height:300px;
    margin:0;
  }
  .ContentCardContainer, .EmptyCard{
    width:100%;
  }
  .EmptyCard{
    display:block;
  }
  .ContentContainer{
    width:70%;
  }
  .ContentCardTitle{
    font-size:.7em;
    margin-bottom: 0;
  }
  .Logo{
    margin-left:0;
  }
  .LandingDiamond{
    margin-left:20px;
    margin-right:20px;
    margin-top: auto;
    margin-bottom: auto;
    display:block;
  }
}

@media screen and (min-width: 1200px) {
  .MainColumnWidth, .FeatureCardMetaContainer{
    width:70%;
    max-width:70%;
    min-width: 70%;
  }
}

a{
  color:var(--ArtNouveauPink)
}

@media screen and (min-width: 1400px) {
  .FeatureCardDecoContainer{
    width: calc(70% - 0px);
    max-width: calc(70% - 0px);
    margin-top:20px;
  }
  .FeatureCardDeco{
    border-left: var(--HighlightPrimary) solid 5px;
  }
  .FeatureCardMetaContainer{
    width:100%;
    max-width:100%;
    margin-left:45px;
    margin-right:45px;
  }
  .LeftAbsolute{
    left:-50px;
  }
  
  .RightAbsolute{
    right:-50px;
  }
}

@media screen and (min-width: 1600px) {
  .ContentGrid{
    min-height:375px;
  }
  .ContentCard{
    min-height:375px;
  }
  .ContentCardTitle{
    font-size:.8em;
  }
  .Gradient{
    height:60px;
    opacity:.5;
  }
}

@media screen and (min-width: 1800px) {
  .MainColumnWidth{
    width:60%;
    max-width: 60%;
    min-width: 60%;
  }

  .ContentContainer{
    width:45%;
    max-width: 45%;
  }


  .FeatureCardDecoContainer{
    width: calc(60% - 5px);
    max-width: calc(60% - 5px);
    margin-top:40px;
  }
}